const initializeFoundation = () => {
  /*
   * FIXME: hack to make Magellan work in a sidebar.
   * Can be replaced by the offset_by_height option in Foundation 5.5.
   * https://github.com/zurb/foundation/pull/5801
   */
  const sidebarMenuHeight = $('[data-magellan-expedition]').outerHeight();

  const BLANK_RE = /^\s*$/;

  $(document).foundation({
    'magellan-expedition': {
      active_class: 'is-current',
      destination_threshold: -sidebarMenuHeight,
      throttle_delay: 100
    },
    tooltip: {
      touch_close_text: __('foundation.tooltip.touch_close_text')
    },
    abide: {
      validators: {
        valuePlaceholder: function (el, required) {
          // At least one, non-whitespace character should be present in the textarea.
          // So newlines alone will not be valid.
          if (required && BLANK_RE.test(el.value)) {
            return false;
          } else if (el.value === el.getAttribute('data-placeholder')) {
            return false;
          } else {
            return true;
          }
        }
      }
    }
  });

  function toggleSidebarNavigationBasedOnTopbarVisibility() {
    const $topBar = $('.top-bar').first();
    const isTopNavigationExpanded = $topBar.hasClass('expanded');
    $('.m-navigation').toggleClass(
      'm-navigation--expanded',
      isTopNavigationExpanded
    );
  }

  const originalTopBarToggle = window.Foundation.libs.topbar.toggle;
  window.Foundation.libs.topbar.toggle = function (...args) {
    originalTopBarToggle.call(this, ...args);
    toggleSidebarNavigationBasedOnTopbarVisibility();
  };
};

export default initializeFoundation;
