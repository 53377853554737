// @flow
import { formatPrice } from 'utils/currencyUtils';

import {
  type Locale,
  type Localizable,
  type Localized,
  localizable,
  localize
} from './locale';

export opaque type RawMoney: number = number;

export function rawMoney(n: number): RawMoney {
  return n;
}

export type Money = Localizable<RawMoney>;

export function money(currency: Locale, n: number): Money {
  return localizable(currency, n);
}

export opaque type FormattedMoney: string = Localized<string>;

export type FormatMoneyOptions = {|
  alwaysShowDecimals?: boolean
|};
const defaultFormatMoneyOptions: FormatMoneyOptions = Object.freeze({});

export function formatMoney(
  fm: Money,
  options?: FormatMoneyOptions = defaultFormatMoneyOptions
): FormattedMoney {
  return localize(fm, (locale, number) => {
    // If the price is a round number, leave the decimals out
    if (!options.alwaysShowDecimals && Number.isInteger(number)) {
      return formatPrice(number, { precision: 0, locale: locale });
    }
    // Otherwise render with locale specific precision
    return formatPrice(number, { locale: locale });
  });
}

export function isEqual(a: Money, b: Money): boolean {
  return JSON.stringify(a) === JSON.stringify(b);
}
