// @flow

import { v5 as uuidv5 } from 'uuid';

// NOTE: Keep in sync with lib/tracking/user_id.rb

// These are the bytes from string 'Venuu/tracking_d'.
const FIRST_16_BYTES = [
  86, 101, 110, 117, 117, 47, 116, 114, 97, 99, 107, 105, 110, 103, 95, 100
];
// This is the remaining part of 'Venuu/tracking_distinct_id' after the first 16 bytes
const REMAINING_STRING = 'istinct_id';

export function generateUserId({ userEmail }: {| userEmail: string |}): string {
  // We can only provide 16 bytes worth of data as the UUID v5 namespace value
  // for the `uuid` npm package, so 'istinct_id' part needs to be prepended
  // to the uuidv5 input value.
  //
  // This way we get the same output on JS side as we would get on Ruby side
  // if we ran this:
  //
  // Digest::UUID.uuid_v5('Venuu/tracking_distinct_id', user_email.downcase.to_s)
  return uuidv5(
    `${REMAINING_STRING}${userEmail.toLowerCase()}`,
    FIRST_16_BYTES
  );
}
