// @flow

import type { JSONValue, JSONObject } from 'common/types';
import { failure } from 'common/json/extract';

export default function object<V>(extract: JSONObject => V): JSONValue => V {
  return json => {
    if (!json) {
      return failure('Expecting an object, got a falsy value instead', () =>
        extract({})
      );
    }
    if (typeof json !== 'object') {
      return failure(`Expecting an object, got ${typeof json} instead`, () =>
        extract({})
      );
    }
    if (Array.isArray(json)) {
      return failure('Expecting an object, got an array instead', () =>
        extract({})
      );
    }
    return extract(json);
  };
}
