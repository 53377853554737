/**
 * Imports that used to come via Rails assets pipeline.
 *
 * We must use `require` to get the jQuery global registered before
 * loading scripts that rely on the global `jQuery` to be present
 */
require('vendorJS/modernizr');

const jQuery = require('vendorJS/jquery');
window.jQuery = jQuery;
window.$ = jQuery;

require('vendorJS/jquery_ujs');
require('jquery.cookie');
require('vendorJS/purl');
require('vendorJS/waypoints');
require('vendorJS/waypoints-sticky');
require('vendorJS/cocoon');

require('foundation-sites/js/foundation/foundation');
require('foundation-sites/js/foundation/foundation.alert');
require('foundation-sites/js/foundation/foundation.equalizer');
// require('foundation-sites/js/foundation/foundation.clearing');
// require('foundation-sites/js/foundation/foundation.cookie');
// require('foundation-sites/js/foundation/foundation.dropdown');
// require('foundation-sites/js/foundation/foundation.joyride');
require('foundation-sites/js/foundation/foundation.magellan');
// require('foundation-sites/js/foundation/foundation.orbit');
require('foundation-sites/js/foundation/foundation.reveal');
require('foundation-sites/js/foundation/foundation.tooltip');
require('foundation-sites/js/foundation/foundation.topbar');
require('foundation-sites/js/foundation/foundation.tab');
// require('foundation-sites/js/foundation/foundation.interchange');
// require('foundation-sites/js/foundation/foundation.placeholder');
require('vendorJS/foundation.abide');
require('vendorJS/foundation.topbar');
