// https://webpack.js.org/configuration/output/#output-publicpath
//
// > In cases where the publicPath of output files can't be known at compile time, it can be
// > left blank and set dynamically at runtime in the entry file using the free variable
// > __webpack_public_path__

import { retrieve } from 'putki';

// On server side, we set the public path as a global variable
// instead of going through Putki as the value needs to be known
// before Putki has been populated.
if (typeof SERVER_RENDERER_PUBLIC_PATH === 'string') {
  // eslint-disable-next-line no-undef, camelcase
  __webpack_public_path__ = SERVER_RENDERER_PUBLIC_PATH;
} else {
  // On client side, though, we know the public path via Putki.

  // eslint-disable-next-line no-undef, camelcase
  __webpack_public_path__ = retrieve('webpack_public_path');
}
