// @flow

import { retrieve } from 'putki';
import $ from 'jquery';

import { type JSONObject } from 'common/types';
import currentSite from 'site/currentSite';

function getSocialMedia(input: string) {
  switch (false) {
    case input.indexOf('facebook.com') === -1:
      return 'Facebook';
    case input.indexOf('t.co') === -1:
      return 'Twitter';
    case input.indexOf('twitter.com') === -1:
      return 'Twitter';
    default:
      return null;
  }
}

// Copied from Mixpanel JS source code
// https://github.com/mixpanel/mixpanel-js/blob/8ac526e5cb8563d11e2206046ab986c6491ac6d7/src/utils.js#L1571-L1593
function inferOperatingSystem() {
  const a = navigator.userAgent;
  if (/Windows/i.test(a)) {
    if (/Phone/.test(a) || /WPDesktop/.test(a)) {
      return 'Windows Phone';
    }
    return 'Windows';
  } else if (/(iPhone|iPad|iPod)/.test(a)) {
    return 'iOS';
  } else if (/Android/.test(a)) {
    return 'Android';
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(a)) {
    return 'BlackBerry';
  } else if (/Mac/i.test(a)) {
    return 'Mac OS X';
  } else if (/Linux/.test(a)) {
    return 'Linux';
  } else if (/CrOS/.test(a)) {
    return 'Chrome OS';
  } else {
    return '';
  }
}

function defaultProperties() {
  const site = currentSite();
  const controller = String(retrieve('controller'));
  const action = String(retrieve('action'));
  return {
    Path: document.location.pathname,
    'Social Media': getSocialMedia(window.document.referrer),
    Locale: __LOCALE__,
    'Site Key': site.key,
    // Use specifically `category` to set "Event Category" in Google Analytics.
    category: site.key,
    // Use specifically `label` to set "Event Label" in Google Analytics.
    label: __LOCALE__,
    'Is Language Version Active': site.locale !== __LOCALE__,
    'Action ID': `${controller}#${action}`,
    $os: inferOperatingSystem()
  };
}

function defaultContext() {
  return {
    locale: __LOCALE__
  };
}

const propertiesFromTrackedLink = (link: HTMLElement) => {
  const $link = $(link);
  const linkData = $link.data('venuu-t') || {};
  return {
    ...defaultProperties(),
    ...linkData,
    'Link Text': $link.text().trim(),
    URL: $link.attr('href')
  };
};

const allHaveData = ($elements: any, dataKey: string) => {
  return $elements
    .map(function (this: HTMLElement) {
      return $(this).data(dataKey);
    })
    .get()
    .every(data => !!data);
};

export function venueAnalyticsProps(): JSONObject {
  const dataFromBackend = retrieve('analytics');
  if (
    dataFromBackend !== null &&
    typeof dataFromBackend === 'object' &&
    !Array.isArray(dataFromBackend)
  ) {
    return dataFromBackend;
  }
  return {};
}

/**
 * Tracks clicks on the given element/s.
 *
 * @throws TypeError if all elements do not have a proper data-venuu-t attribute
 * @throws TypeError if all elements do not have a proper data-venuu-t-name attribute
 */
export function trackClicksOn(elements: HTMLElement | Array<HTMLElement>) {
  const $elements = $(elements);

  if ($elements.length === 0) {
    return;
  }

  if (!allHaveData($elements, 'venuu-t')) {
    throw new TypeError(
      'trackClicksOn requires all elements to have a "venuu-t" data attribute'
    );
  }

  if (!allHaveData($elements, 'venuu-t-name')) {
    throw new TypeError(
      'trackClicksOn requires all elements to have a "venuu-t-name" data attribute'
    );
  }

  window.analytics.trackLink(
    elements,
    link => $(link).data('venuu-t-name'),
    propertiesFromTrackedLink
  );
}

/**
 * Removes the click tracking from the given element.
 *
 * @param {HTMLElement} element - The element which has its clicks tracked
 */
export function untrackClicksOn(_element: HTMLElement) {
  // FIXME: is there any way to disable window.analytics.trackLink?
}

/**
 * Remember update the server-side matchers (lib/tracking/actor_inference.rb)
 * if you make changes here.
 */
export function isBot(userAgent: string): boolean {
  const BOT_MATCHERS = [
    /google web preview/i,
    /baiduspider/i,
    /yahoo! slurp/i,
    /rsiteauditor/i,
    // This matches most currently known bots and likely future bots as well.
    /bot/i
  ];
  return BOT_MATCHERS.some(matcher => {
    return matcher.test(userAgent);
  });
}

/**
 * Tracks a single event.
 *
 * This function also adds some default properties to the event for convenience.
 *
 * @param {String} eventName - The name of the event
 * @param {Object} properties - Extra properties associated with the event
 */
export function trackEvent(eventName: string, properties: Object = {}) {
  if (isBot(navigator.userAgent)) return;

  window.analytics.track(
    eventName,
    {
      ...defaultProperties(),
      ...properties
    },
    defaultContext()
  );
}
