// @flow

export opaque type Empty: null = null;
export opaque type NonEmptyList<V>: $ReadOnlyArray<V> = $ReadOnlyArray<V>;

export default function nonEmptyList<V>(
  head: V,
  tail?: NonEmptyList<V> | Empty | $ReadOnlyArray<V>
): NonEmptyList<V> {
  return [head, ...(tail || [])];
}

export function maybeNonEmptyList<V>(
  list: $ReadOnlyArray<V>
): NonEmptyList<V> | Empty {
  if (list.length > 0) {
    return list;
  }
  return null;
}

export function foldMaybeNonEmptyList<Input, Output>(
  list: NonEmptyList<Input> | Empty,
  branches: {|
    empty: () => Output,
    nonEmpty: (NonEmptyList<Input>) => Output
  |}
): Output {
  if (list === null) {
    return branches.empty();
  }
  return branches.nonEmpty(list);
}

export function withHeadAndTail<Input, Output>(
  list: NonEmptyList<Input>,
  f: (Input, NonEmptyList<Input> | Empty) => Output
): Output {
  const [head, ...tail] = list;
  return f(head, maybeNonEmptyList(tail));
}

export function peekNonEmptyList<V>([head]: NonEmptyList<V>): V {
  return head;
}
