// @flow

import type { JSONValue } from 'common/types';
import { failure } from 'common/json/extract';

export default function <V>(value: V): JSONValue => V {
  return (json: JSONValue) => {
    if (json === value) {
      return value;
    }
    return failure(
      `Expecting specific value of type ${typeof value}, got another value of type ${typeof json}`,
      () => value
    );
  };
}
