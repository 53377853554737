// @flow

import type { Site } from './types';
import allSites from './allSites';
import { retrieve } from 'putki';

export default function currentSite(): Site {
  const sites = allSites();
  const key = retrieve('current_site');

  const site = sites.find(s => s.key === key);
  return site || sites[0];
}
