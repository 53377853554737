// @flow

import type { JSONValue, JSONObject } from 'common/types';

type Map<K, V> = { [key: K]: V, ... };
type AnyObject = { +[key: string]: any, ... };

export default function map<K, V>(
  extractKey: JSONValue => K,
  extractValue: JSONValue => V
): JSONObject => Map<K, V> {
  return json =>
    Object.keys((json: AnyObject)).reduce((m: Map<K, V>, key): Map<K, V> => {
      m[extractKey(key)] = extractValue(json[key]);
      return m;
    }, {});
}
