// @flow

import type { JSONValue, JSONObject } from 'common/types';

export function value(json: JSONValue): JSONValue {
  return json;
}

export function object(jsonObject: JSONObject): JSONObject {
  return jsonObject;
}
