// @flow

import type { JSONValue } from 'common/types';
import { failure } from 'common/json/extract';

export const boolean: JSONValue => boolean = (json: JSONValue) => {
  if (typeof json === 'boolean') {
    return json;
  }
  return failure(
    `Expecting boolean, got ${json === null ? 'null' : typeof json}`,
    () => Boolean(json)
  );
};

export const string: JSONValue => string = (json: JSONValue) => {
  if (typeof json === 'string') {
    return json;
  }
  return failure(
    `Expecting string, got ${json === null ? 'null' : typeof json}`,
    () => String(json)
  );
};

export const number: JSONValue => number = (json: JSONValue) => {
  if (typeof json === 'number') {
    return json;
  }
  return failure(
    `Expecting number, got ${json === null ? 'null' : typeof json}`,
    () => Number(json)
  );
};

export const mixed: JSONValue => mixed = (json: JSONValue) => {
  return json;
};
