// @flow

import type { JSONValue } from 'common/types';

export default function withDefault<V>(
  extract: JSONValue => V,
  defaultValue: V
): JSONValue => V {
  return json => {
    if (json === null || typeof json === 'undefined') {
      return defaultValue;
    }
    return extract(json);
  };
}
