// @flow

import type { JSONValue, JSONArray } from 'common/types';
import { failure } from 'common/json/extract';

export default function array<V>(extract: JSONArray => V): JSONValue => V {
  return json => {
    if (!Array.isArray(json)) {
      return failure(
        `Expecting an array, got ${
          json === null ? 'null' : typeof json
        } instead`,
        () => extract([])
      );
    }
    return extract(json);
  };
}
