// @flow

import { datadogRum } from '@datadog/browser-rum';
import { retrieve } from 'putki';
import { consentGivenForCategory } from 'utils/cookieConsent';

const environment = window.__ENVIRONMENT__;

let initialized = false;
function initializeDatadogRum() {
  if (initialized) return;
  initialized = true;

  // sessionSampleRate:
  //   The percentage of sessions to track: 100 for all, 0 for none. Only
  //   tracked sessions send RUM events. For more details about
  //   sessionSampleRate, see the sampling configuration.
  //    -> https://docs.datadoghq.com/real_user_monitoring/guide/sampling-browser-plans/
  //
  // sessionReplaySampleRate:
  //   The percentage of tracked sessions with Browser RUM & Session Replay
  //   pricing features: 100 for all, 0 for none. For more details about
  //   sessionReplaySampleRate, see the sampling configuration.
  //    -> https://www.datadoghq.com/pricing/?product=real-user-monitoring--session-replay#real-user-monitoring--session-replay
  //    -> https://docs.datadoghq.com/real_user_monitoring/guide/sampling-browser-plans/

  const { sessionSampleRate, sessionReplaySampleRate } = (() => {
    switch (environment) {
      case 'test':
        // We don't want test environment to send any RUM events but we do
        // want our app to crash in test if for some reason the Datadog RUM
        // code messes up our application.
        return { sessionSampleRate: 0, sessionReplaySampleRate: 0 };
      case 'development':
        // We don't want to incur pricing costs in development.
        // This number can be changed when we want to test the RUM code.
        return { sessionSampleRate: 0, sessionReplaySampleRate: 0 };
      default:
        // We only want a very small portion of our users triggering this
        // as it might prove to be really costly.
        //
        // We are not using session replays, though, so we disable that.
        return { sessionSampleRate: 5, sessionReplaySampleRate: 0 };
    }
  })();

  const controller = retrieve('controller');
  const action = retrieve('action');
  datadogRum.init({
    applicationId: '1b89e864-e532-4578-a391-cf29b0edb549',
    clientToken: 'pubf765aeae3809f6105bb0c168ce3687bd',
    site: 'datadoghq.eu',
    service: 'venuu',
    env: environment,
    version: retrieve('application_version_git'),
    sessionSampleRate,
    sessionReplaySampleRate,
    allowedTracingUrls: [/^https:\/\/(?:dev\.|staging\.)?venuu\.(?:fi|se|com)/],
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    beforeSend: event => {
      if (typeof controller === 'string' && typeof action === 'string') {
        // Datadog shows this in RUM session explorer for view names:
        //
        // > RUM SDK supports custom view names, which can be used to override
        // > `url_path_group`. If not specified, it will have the same content
        // > of `view.url_path_group`.
        //
        // So in order for venue pages, search pages etc. to be monitorable as
        // their own groups, we can set up a custom view name that uses the
        // Rails controller name and actions.
        //
        // This way, regardless of how pretty our URLs are, the grouping will
        // match the way we write our pages. And we can finally monitor how long
        // venue profile pages take to load.
        event.view.name = `${controller}#${action}`;
      }
    },
    defaultPrivacyLevel: 'mask-user-input'
  });

  datadogRum.startSessionReplayRecording();
}

document.addEventListener('cookieyes_consent_update', eventData => {
  // $FlowIgnore[prop-missing] -- https://www.cookieyes.com/documentation/events-on-cookie-banner-interactions/
  const data = eventData.detail;
  if (data.accepted.includes('performance')) {
    initializeDatadogRum();
  }
});

if (consentGivenForCategory('performance') || environment === 'test') {
  initializeDatadogRum();
}
