// @flow

// This component is to be passed down as prop to
// the HoneybadgerErrorBoundary component

import * as React from 'react';
import Button from 'common/components/Button';
import styles from './ErrorComponent.scss';

export default function ErrorComponent(): React.Node {
  return (
    <div>
      <p className={styles.errorMessage}>{__('error.something_went_wrong')}</p>
      <Button
        onClick={() => {
          window.location.reload();
        }}
        type="primary"
      >
        {__('error.reload')}
      </Button>
    </div>
  );
}
