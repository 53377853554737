// @flow

import moment from 'moment';

import type { JSONValue } from 'common/types';
import { failure } from 'common/json/extract';

export opaque type DateString<DateFormat: string>: string = string;

export function dateString<DateFormat: string>(
  format: DateFormat
): JSONValue => DateString<DateFormat> {
  return (json: JSONValue) => {
    if (typeof json === 'string') {
      if (moment.utc(json, format, true).isValid()) {
        return json;
      }
      return failure(
        `Expecting dateString (string with format ${format}), got a string "${json}"`,
        () => String(json)
      );
    }
    return failure(
      `Expecting dateString (string with format ${format}), got ${
        json === null ? 'null' : typeof json
      }`,
      () => String(json)
    );
  };
}
