import initializeAnalytics from './initializeAnalytics';
$(document).ready(initializeAnalytics);

import './external-tools';
import initializeFoundation from './foundation';
import './stickySidebar';
import './setupDatadog';
import './setupHoneybadger';
import './whatInput';

$(document).ready(initializeFoundation);
