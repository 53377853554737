// @flow

import { oneOf } from 'common/json/extract';

type CookieCategory =
  | 'necessary'
  | 'functional'
  | 'performance'
  | 'analytics'
  | 'advertisement'
  | 'other';

const cookieCategories: Array<CookieCategory> = [
  'necessary',
  'functional',
  'performance',
  'analytics',
  'advertisement',
  'other'
];

export function getConsentedCookieCategories(
  cookies: string
): Array<CookieCategory> {
  const re = new RegExp('cookieyes-consent=([^;]+)');
  const rawValue = re.exec(cookies);
  const cookieValue = rawValue !== null ? unescape(rawValue[1]) : null;

  if (cookieValue) {
    const cookiePairs = cookieValue.split(',').map(pair => pair.trim());
    const cookieArr = [];
    cookiePairs.forEach(pair => {
      const [key, value] = pair.split(':');
      if (cookieCategories.includes(key) && value === 'yes') {
        const category = oneOf(cookieCategories)(key);
        cookieArr.push(category);
      }
    });

    return cookieArr;
  }
  return [];
}

export function consentGivenForCategory(category: CookieCategory): boolean {
  return getConsentedCookieCategories(document.cookie).includes(category);
}
