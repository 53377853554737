// @flow

import type { JSONValue } from 'common/types';
import { type Money, money } from 'common/types/money';
import { object, record, decimal, locale } from 'common/json/extract';

const extractValues = object(
  record({
    currency: locale,
    amount: decimal
  })
);

export default function moneyExtract(json: JSONValue): Money {
  const extractedMoney = extractValues(json);
  return money(extractedMoney.currency, extractedMoney.amount);
}
