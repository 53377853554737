import 'boot/webpackPublicPath';
import 'boot/vendor';
import 'boot/common/setMomentLocale';
import 'boot/common/exportReact';

import './globalSetup';

import './exposeComponents';

import { mountAllComponents } from 'boot/common/reactMount';
$(document).ready(mountAllComponents);

import './hookActionSpecificSetup';

import i18nSetup from 'boot/common/i18nSetup';
i18nSetup();
