// Load all social media plugins only after page has been fully loaded. This
// reduces the payload needed to get the page visible and running -- not any of
// these plugins is the reason why the user comes to the site, so they should be
// lazily loaded.
//
// Also, these plugins are loaded only on certain pages on the site, so we need
// to specify those conditions first using a custom callback.

import { retrieve } from 'putki';

const socialMediaInitCondition = () => {
  const controller = retrieve('controller');
  const action = retrieve('action');

  if (controller === 'price_requests' && action === 'thanks') {
    return true;
  } else {
    // No-one else needs it.
    return false;
  }
};

const lazyloadPlugins = () => {
  if (retrieve('disable_external_js')) return;

  $(window).load(() => {
    // Facebook
    window.fbAsyncInit = function () {
      /* global FB */
      FB.init({
        xfbml: true
      });
    };

    // Disable warnings about copypasta
    /* eslint-disable prefer-const, one-var */
    (function (d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.async = true;
      js.src = `https://connect.facebook.net/${__(
        'locale_and_country_code'
      )}/all.js`;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
    /* eslint-enable */
  });
};

$(document).ready(() => {
  if (socialMediaInitCondition()) {
    lazyloadPlugins();
  }
});
