/**
 * Function to get cookies as an object of name/value pairs
 *
 * TODO: should we be / are we using jquery.cookie instead?
 */
export default function getCookies() {
  const cookies = {};
  const all = document.cookie;
  if (all === '') {
    return cookies;
  }
  const list = all.split('; ');
  for (let i = 0; i < list.length; i++) {
    const cookie = list[i];
    const p = cookie.indexOf('=');
    const name = cookie.substring(0, p);
    let value = cookie.substring(p + 1);
    value = decodeURIComponent(value);
    cookies[name] = value;
  }
  return cookies;
}
