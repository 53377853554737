// @flow

import accounting from 'accounting';

export function formatPrice(
  number: string | number,
  options: Object = {}
): string {
  const translatedOptions = {
    precision: I18n.t('number.currency.format.precision', options),
    thousand: I18n.t('number.currency.format.delimiter', options),
    decimal: I18n.t('number.currency.format.separator', options),
    format: I18n.t('number.currency.format.accounting.format', options),
    symbol: I18n.t('number.currency.format.unit', options),
    ...options
  };
  return accounting.formatMoney(number, translatedOptions);
}
