// @flow

import type { JSONValue } from 'common/types';

export function retrieve(identifier: string): JSONValue {
  const value = base()[identifier];

  if (typeof value === 'undefined') {
    return null;
  }
  return value;
}

function base() {
  return (window && window.Putki) || {};
}
