// @flow

import type { NonEmptyList } from './nonEmptyList';
import nonEmptyList, {
  withHeadAndTail,
  foldMaybeNonEmptyList,
  peekNonEmptyList
} from './nonEmptyList';

import {
  type Locale,
  locales as webpackLocales
} from 'railsRoot/config/webpack/availableLocales';
export type { Locale } from 'railsRoot/config/webpack/availableLocales';
export const locales: $ReadOnlyArray<Locale> = webpackLocales;

export opaque type Localizable<LocalizableValue> = {|
  locale: Locale,
  value: LocalizableValue
|};

export opaque type Localized<LocalizedValue>: LocalizedValue = LocalizedValue;

export function localizable<Value>(
  locale: Locale,
  value: Value
): Localizable<Value> {
  return {
    locale,
    value
  };
}

export function localize<Input, Output>(
  { locale, value }: Localizable<Input>,
  f: (Locale, Input) => Output
): Localized<Output> {
  return f(locale, value);
}

export function sequenceLocalizable<V>(
  list: NonEmptyList<Localizable<V>>
): Localizable<NonEmptyList<V>> {
  return withHeadAndTail(list, (head, tail) => {
    return localizable(
      head.locale,
      nonEmptyList(
        head.value,
        foldMaybeNonEmptyList(tail, {
          empty: () => [],
          nonEmpty: nonEmptyTail => {
            const tailHead = peekNonEmptyList(nonEmptyTail);
            if (head.locale !== tailHead.locale) {
              throw new Error('Invariant violation: locales need to match');
            }
            // NOTE: We are recursing here; hope your list isn't too lengthy.
            return sequenceLocalizable(nonEmptyTail).value;
          }
        })
      )
    );
  });
}
