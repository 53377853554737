import satisfiesMediaQuery from 'utils/satisfiesMediaQuery';

$(() => {
  if (!$('.m-box-sticky-container').length) {
    return;
  }

  const $sidebar = $('.m-box-sticky-container');

  const $footer = $('.js-sticky-sidebar-end, footer').first();
  const $wrapper = () => $('.sticky-wrapper');
  const wrapperWidth = () => $wrapper().outerWidth();

  $sidebar.waypoint('sticky', {
    stuckClass: 'm-box-sticky',
    offset: 20,
    handler: () => $sidebar.css({ width: wrapperWidth() })
  });

  $footer.waypoint(
    direction => {
      if (direction === 'down') {
        const offset =
          $footer.offset().top -
          $wrapper().offset().top -
          $sidebar.outerHeight();
        $sidebar.css({
          position: 'absolute',
          top: offset,
          width: wrapperWidth()
        });
      } else if (direction === 'up') {
        $sidebar
          .removeAttr('style')
          .css({ width: wrapperWidth() })
          .addClass('m-box-sticky');
      }
    },
    {
      offset: () => $sidebar.outerHeight() + 20
    }
  );

  const resize = function () {
    if (satisfiesMediaQuery('middle')) {
      $.waypoints('enable');
    } else {
      $sidebar.attr('style', '').removeClass('m-box-sticky');
      $wrapper().removeAttr('style');
      $.waypoints('disable');
    }
  };
  $(window).on('resize', resize);
  resize();
});
