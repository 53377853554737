// @flow

import * as React from 'react';
import classnames from 'classnames';

import styles from './Button.scss';

export type Props = {
  children: React.Node,
  className?: string,
  type?: 'default' | 'success' | 'primary' | 'danger' | 'link' | 'text',
  disabled?: boolean,
  submit?: boolean,
  onClick?: Event => mixed,
  ...
};

export default function Button(props: Props): React.Node {
  const {
    children,
    onClick,
    className,
    type = 'default',
    disabled = false,
    submit,
    ...otherProps
  } = props;
  const buttonClassName = classnames(className, styles[type]);

  const handleClick = (e: Event) => {
    if (onClick) {
      e.preventDefault();
      onClick(e);
    }
  };

  return (
    <button
      {...otherProps}
      type={submit ? 'submit' : 'button'}
      onClick={handleClick}
      className={buttonClassName}
      tabIndex={props.disabled ? '-1' : '0'}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
