// @flow

import Honeybadger from '@honeybadger-io/js';
import { retrieve } from 'putki';
import actionEntryPoints from './actionEntryPoints';
import $ from 'jquery';

function buildModuleName(controller: string, action: string) {
  const camelCaseController = controller.replace(/(_\w)/g, match =>
    match[1].toUpperCase()
  );
  const upcaseAction = action.replace(/^./, match => match.toUpperCase());
  return `${camelCaseController}${upcaseAction}`;
}

function actionAlias(action: string): string {
  const crudAliases: { [string]: ?string } = {
    update: 'edit',
    create: 'new'
  };
  return crudAliases[action] || action;
}

function findScript(controller: string, action: string) {
  const moduleNameWithAction = buildModuleName(controller, actionAlias(action));
  const moduleNameWithoutAction = buildModuleName(controller, '');

  if (typeof actionEntryPoints[moduleNameWithAction] === 'function') {
    const actionFunc = actionEntryPoints[moduleNameWithAction];
    return actionFunc();
  }

  if (typeof actionEntryPoints[moduleNameWithoutAction] === 'function') {
    const actionFunc = actionEntryPoints[moduleNameWithoutAction];
    return actionFunc();
  }

  return Promise.resolve({ default: () => {} });
}

const controller = retrieve('controller');
const action = retrieve('action');

if (typeof controller === 'string' && typeof action === 'string') {
  findScript(controller, action)
    .then(module => module.default)
    .then(initializer => {
      $(document).ready(() => {
        try {
          initializer();
        } catch (err) {
          Honeybadger.notify(err);
          // The error is thrown as that was what honeybadger-js v2.x used to do when
          // we were wrapping the initializer with Honeybadger.wrap().
          //
          // It doesn't make much sense as nothing is going to catch this error.
          //
          // TODO: Handle an initializer error gracefully.
          throw err;
        }
      });
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.error(err);
    });
}
