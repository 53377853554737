// @flow

import $ from 'jquery';
import { retrieve } from 'putki';
import currentSite from 'site/currentSite';
import getCookies from 'utils/getCookies';
import { consentGivenForCategory } from 'utils/cookieConsent';
import { generateUserId } from 'utils/trackingUserId';
import { trackEvent, trackClicksOn } from 'utils/analytics';
import {
  object,
  record,
  nullable,
  string,
  withDefault
} from 'common/json/extract';

import { retrieveRemarketingProperties } from './analytics/remarketing';

function loadAnalytics() {
  window.analytics.load(retrieve('segment_write_key'));

  window.analytics.setAnonymousId(getCookies()['cheekify_a']);
}

function loadAnalyticsOnConsent() {
  document.addEventListener('cookieyes_consent_update', eventData => {
    // $FlowIgnore[prop-missing] -- https://www.cookieyes.com/documentation/events-on-cookie-banner-interactions/
    const data = eventData.detail;
    if (data.accepted.includes('analytics')) {
      loadAnalytics();
    }
  });
}

/**
 * Rebinds tracking of anchor elements with data-venuu-t attributes.
 */
function rebindTrackedLinks() {
  trackClicksOn($('a[data-venuu-t]:not([data-venuu-t-react])'));
}

/**
 * Sends an "Arrived from Campaign" tracking event, if visitor arrived from
 * a Venuu campaign site.
 */
function trackReferringCampaigns() {
  type CampaignDataObject = {
    'Campaign Source': string,
    'Campaign Name': string,
    'Campaign Medium'?: string,
    ...
  };

  const campaignDataFromUrl: string => null | CampaignDataObject = urlParam => {
    let url;
    try {
      // URLs which purl can't parse might throw. Don't throw in that case.
      // See https://github.com/venuu/venuu/issues/749
      url = $.url(urlParam);
    } catch (e) {
      return null;
    }

    const soundboard = url.attr('host').match(/(cheek|putous)\.venuu.fi/i);
    if (soundboard) {
      // Real result should be in the second cell of Array returned by match,
      // but browsers might differ on whether they return the full match as the
      // first cell or not. Work around that pre-emptively.
      const campaignName =
        soundboard.length === 2 ? soundboard[1] : soundboard[0];
      return {
        'Campaign Source': 'Soundboard',
        'Campaign Name': campaignName.toLowerCase()
      };
    } else {
      return null;
    }
  };

  const campaignData = campaignDataFromUrl(window.document.referrer);
  if (campaignData) {
    campaignData['Campaign Medium'] = 'Referral';
    window.analytics.identify(campaignData);
    trackEvent('Arrived from Campaign', campaignData);
  }
}

const extractUserTrackingData = withDefault(
  object(
    record({
      email: nullable(string),
      customer_type: nullable(string)
    })
  ),
  { email: null, customer_type: null }
);

/**
 * Persists the gclid parameter from the url or the _gcl_aw-cookie to our own cookie.
 *
 * This is done to ensure that we are not fully dependent on the poorly documented
 * Google conversion linker and the corresponding _gcl_aw cookie.
 */
function persistGclid() {
  let gclidFromUrl: ?string;
  try {
    // URLs which purl can't parse might throw. Don't throw in that case.
    gclidFromUrl = $.url().param('gclid');
  } catch (e) {
    // no-op
  }

  const gclidCookie: ?string = $.cookie('_gcl_aw');
  const gclidFromCookie: ?string = gclidCookie && gclidCookie.split('.')[2];

  const gclid = gclidFromUrl || gclidFromCookie;

  if (gclid) {
    $.cookie('venuu_gclid', gclid, { expires: 90, path: '/' });
  }
}

function distinctIdFromUrl() {
  try {
    // URLs which purl can't parse might throw. Don't throw in that case.
    // See https://github.com/venuu/venuu/issues/749
    return $.url().param('distinct_id');
  } catch (e) {
    return null;
  }
}

function emailIdFromUrl() {
  let rawEmailDistinctId: string | null = null;
  try {
    // URLs which purl can't parse might throw. Don't throw in that case.
    // See https://github.com/venuu/venuu/issues/749
    rawEmailDistinctId = $.url().param('email_distinct_id');
  } catch (e) {
    // no-op
  }
  if (!rawEmailDistinctId) {
    return null;
  }
  return generateUserId({ userEmail: rawEmailDistinctId });
}

/**
 * The following functions are a part of the improved user tracking scheme.
 * ------------------------------------------------
 * Documentation: https://github.com/venuu/venuu/pull/7368
 */
function identifyUser() {
  const userIdFromUrl = distinctIdFromUrl();
  const emailUserIdFromUrl = emailIdFromUrl();
  const userIdFromCookie = getCookies()['cheekify_uid'];
  const { email, customer_type: customerType } = extractUserTrackingData(
    retrieve('user_tracking_data')
  );

  const commonTraits = {
    'Application Version': retrieve('application_version'),
    'Site Key': currentSite().key
  };

  if (userIdFromCookie && email) {
    const traits = {
      ...commonTraits,
      Source: 'Venuu web app',
      'Customer Type': customerType,
      email: email
    };
    if (traits['Customer Type'] === null) {
      delete traits['Customer Type'];
    }
    window.analytics.identify(userIdFromCookie, traits);
  } else if (userIdFromUrl) {
    // These are returning users
    // so they should customer identification traits set.
    window.analytics.identify(userIdFromUrl, commonTraits);
  } else if (emailUserIdFromUrl) {
    window.analytics.identify(emailUserIdFromUrl, commonTraits);
  }
}

export default function initializeAnalytics() {
  if (consentGivenForCategory('analytics')) {
    loadAnalytics();
  } else {
    loadAnalyticsOnConsent();
  }

  identifyUser();

  const pageOpts = retrieveRemarketingProperties();

  window.analytics.page(pageOpts);

  // Track special links
  rebindTrackedLinks();

  trackReferringCampaigns();

  persistGclid();
}
