import i18nFallbacks from 'railsRoot/config/i18n_fallbacks.json';
import I18n from 'i18n-js';

export default function i18nSetup() {
  window.I18n = I18n;
  I18n.locale = __LOCALE__;
  I18n.defaultLocale = 'fi';
  I18n.translations = I18N_RUNTIME_TRANSLATIONS;
  I18n.placeholder = /%\{(.*?)\}/gm;

  // Configure runtime I18n.t to test each fallback locale in the
  // defined order before falling back to the default missing translation
  // logic -- which most likely would show an error.
  //
  // This replicates the logic we see on Rails side.
  const originalMissingTranslation = I18n.missingTranslation;
  I18n.missingTranslation = function (scope, options) {
    // English translation doesn't define fallback locales, so we
    // default to an empty array of fallback locales to not crash when
    // fallback locales don't exist.
    //
    // This makes a missing translation error show up in case I18n.t
    // ends up using a translation that doesn't exist and we use English.
    const fallbackLocales = i18nFallbacks[this.locale] || [];
    if (options.__usedFallbackLocales >= fallbackLocales.length) {
      return originalMissingTranslation.call(this, scope, options);
    }
    const nextFallbackLocaleIndex =
      typeof options.__usedFallbackLocales === 'undefined'
        ? 0
        : options.__usedFallbackLocales + 1;
    return I18n.t(scope, {
      ...options,
      locale: fallbackLocales[nextFallbackLocaleIndex],
      __usedFallbackLocales: nextFallbackLocaleIndex
    });
  };

  // We want missing placeholders to not output anything in order for the In-Context
  // translation mode to pick the original translation as it was in the language under
  // translation -- that is, we want to effectively disable interpolation altogether.
  if (__LOCALE__ === 'tra') {
    I18n.missingPlaceholder = placeholder => placeholder;
  }
}
