// @flow

import type { JSONObject } from 'common/types';

export default function match<
  Branches: { [string | boolean]: (JSONObject) => any, ... },
  // $FlowFixMe[cannot-resolve-name] -- Suppressed during Flow upgrade to 0.250.0. Delete this comment to view the error.
  BranchValues: $Values<$ObjMap<Branches, <V>((any) => V) => V>>
>(sentinelProperty: string, branches: Branches): JSONObject => BranchValues {
  return (jsonObject: JSONObject): BranchValues => {
    const type = jsonObject[sentinelProperty];
    if (typeof type !== 'string' && typeof type !== 'boolean') {
      throw new Error(
        `Expected type of property "${sentinelProperty}" to be a string or boolean with value being one of [${Object.keys(
          branches
        ).join(
          ', '
        )}]. Received "${sentinelProperty}" typeof was: ${typeof type}.`
      );
    }
    if (typeof branches[type] === 'undefined') {
      throw new Error(
        `Expected value of property "${sentinelProperty}" to be one of [${Object.keys(
          branches
        ).join(', ')}]. Received "${sentinelProperty}": ${String(type)}.`
      );
    }
    return branches[type](jsonObject);
  };
}
