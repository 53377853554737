// @flow

import { retrieve } from 'putki';
import { object, record, nullable, id, oneOf } from 'common/json/extract';

type PageType =
  | 'conversionintent'
  | 'offerdetail'
  | 'home'
  | 'searchresults'
  | 'others';

type GoogleTagParams = {|
  hrental_id: string,
  hrental_pagetype: PageType,
  hrental_totalvalue: number
|};

const extractRemarketingDetails = nullable(
  object(
    record({
      page: oneOf(['conversionintent', 'offerdetail', 'home', 'searchresults']),
      id: nullable(id)
    })
  )
);

export function retrieveRemarketingProperties(): GoogleTagParams {
  const remarketingDetails = extractRemarketingDetails(
    retrieve('remarketing_details')
  ) || {
    page: 'others'
  };

  return {
    hrental_id: remarketingDetails.id || '',
    hrental_pagetype: remarketingDetails.page,
    hrental_totalvalue: 0
  };
}
