// @flow

import * as React from 'react';

import styles from './InstructionVideoEmbed.scss';

type Props = {|
  title: string,
  embedUrl: string
|};

export default function InstructionVideoEmbed(props: Props): React.Node {
  return (
    <div className={styles.container}>
      <h3 className={styles.heading}>{props.title}</h3>
      <div className={styles.frameResizer}>
        <div className={styles.frame}>
          <iframe src={props.embedUrl} frameBorder="0" allowFullScreen />
        </div>
      </div>
    </div>
  );
}
