// @flow

import type { JSONValue } from 'common/types';
import { failure } from 'common/json/extract';

export default function oneOf<Enum: JSONValue>(
  values: Array<Enum> | $ReadOnlyArray<Enum>
): JSONValue => Enum {
  return (json: JSONValue) => {
    const index = values.indexOf(json);
    if (index !== -1) {
      return values[index];
    }
    return failure(
      `Expecting one of [${values.join(', ')}], got ${String(json)}`,
      () => values[0]
    );
  };
}
