// @flow

import Honeybadger from '@honeybadger-io/js';
import { retrieve } from 'putki';

const environment = window.__ENVIRONMENT__;

Honeybadger.configure({
  apiKey: '087b96bb',
  environment: environment,
  revision: retrieve('application_version_git'),
  // Paths inside our sourcemaps start with `webpack:///./` and to make Honeybadger
  // be able to link the errors to our actual source files, we need to define the
  // `projectRoot` as documented here:
  // https://docs.honeybadger.io/lib/javascript/guides/using-source-maps/#improving-error-grouping-and-linking-to-source-code
  projectRoot: 'webpack:///./',
  enableUncaught: false,
  // Reporting unhandled Promise rejections causes massive noise in our Honeybadger
  // and is almost always non-actionable. Rather than reporting them, let's not.
  enableUnhandledRejection: false
});

Honeybadger.beforeNotify(notice => {
  if (suppressCurrentUserAgent()) {
    return false;
  }

  notice.component = retrieve('controller');
  notice.action = retrieve('action');
  console.warn('[Honeybadger]', notice); // eslint-disable-line no-console

  return true;
});

function suppressCurrentUserAgent() {
  if (!window.navigator) return false;
  return window.navigator.userAgent.includes('BingPreview');
}
