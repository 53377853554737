// @flow

import type { JSONValue } from 'common/types';

export default function nullable<V>(
  extract: JSONValue => V
): JSONValue => V | null {
  return json => {
    if (json === null || typeof json === 'undefined') {
      return null;
    }
    return extract(json);
  };
}
