// @flow

import type { JSONValue } from 'common/types';
import { failure } from 'common/json/extract';

const id: JSONValue => string = (json: JSONValue) => {
  if (typeof json === 'string') {
    return json;
  } else if (typeof json === 'number') {
    return String(json);
  }
  return failure(
    `Expecting id (number or string), got ${
      json === null ? 'null' : typeof json
    }`,
    () => String(json)
  );
};

export default id;
