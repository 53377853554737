import Modernizr from 'modernizr';

const mediaTypesToMediaQueries = {
  // Same as Foundation ones, plus middle added by Venuu
  small: 'only screen',
  middle: 'only screen and (min-width: 1024px)',
  medium: 'only screen and (min-width: 40.063em)',
  large: 'only screen and (min-width: 64.063em)',
  xlarge: 'only screen and (min-width: 90.063em)',
  xxlarge: 'only screen and (min-width: 120.063em)',
  smallDevice: 'only screen and (min-device-width: 768px)',
  topbar: 'only screen and (min-width: 48em)'
};

export default function satisfiesMediaQuery(mediaType) {
  if (typeof mediaType !== 'string') {
    throw new TypeError('Media type was not a string');
  }
  const mediaQuery = mediaTypesToMediaQueries[mediaType];
  if (!mediaQuery) {
    throw new TypeError(`Unknown media type "${mediaType}"`);
  }
  return Modernizr.mq(mediaQuery);
}
