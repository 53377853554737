// @flow

type ModuleWithDefaultExport = { default: () => void, ... };

const actionEntryPoints: {
  [actionName: string]: void | (() => Promise<ModuleWithDefaultExport>),
  ...
} = {
  frontPageIndex: () => import('./actionSpecificSetup/frontPageIndex'),
  landingPagesShow: () => import('./actionSpecificSetup/landingPagesShow'),
  listsShow: () => import('./actionSpecificSetup/listsShow'),
  priceRequestsNew: () => import('./actionSpecificSetup/priceRequestsNew'),
  reviewsEdit: () => import('./actionSpecificSetup/reviewsEdit'),
  reviewsNew: () => import('./actionSpecificSetup/reviewsNew'),
  'profiles/reviewsNew': () => import('./actionSpecificSetup/reviewsNew'),
  'profiles/venueProfilesShow': () =>
    import('./actionSpecificSetup/profiles/venueProfilesShow'),

  // $FlowFixMe[incompatible-type] -- Suppressed during Flow upgrade to 0.241.0. Delete this comment to view the error.
  'profiles/admin/cateringProfilesEdit': () =>
    import('./actionSpecificSetup/profiles/admin/cateringProfilesEdit'),
  'catering/admin/menusIndex': () =>
    import('./actionSpecificSetup/catering/admin/menusIndex'),

  'admin/archives': () => import('./actionSpecificSetup/admin/archives'),
  'profiles/admin/venueProfilesEdit': () =>
    import('./actionSpecificSetup/profiles/admin/venueProfilesEdit'),
  'profiles/admin/venueProfilesIndex': () =>
    import('./actionSpecificSetup/profiles/admin/venueProfilesIndex'),

  // $FlowFixMe[incompatible-type] -- Suppressed during Flow upgrade to 0.241.0. Delete this comment to view the error.
  'profiles/supplierProfilesEdit': () =>
    import('./actionSpecificSetup/profiles/supplierProfilesEdit'),

  'staff/imagesIndex': () => import('./actionSpecificSetup/staff/imagesIndex'),
  'staff/prospectsIndex': () =>
    import('./actionSpecificSetup/staff/prospectsIndex'),
  'staff/organizationsEdit': () =>
    import('./actionSpecificSetup/staff/organizationsEdit'),
  'staff/landingPagesEdit': () =>
    import('./actionSpecificSetup/staff/landingPagesEdit.js'),
  'users/registrationsEdit': () =>
    import('./actionSpecificSetup/users/registrationsEdit')
};

export default actionEntryPoints;
